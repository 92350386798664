"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageSize = void 0;
var react_redux_1 = require("react-redux");
var store_1 = require("../../../../store");
var usePageSize = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var pageSize = (0, store_1.usePageSize)();
    var onPageSizeChange = function (newPageSize) { return dispatch((0, store_1.enrichAISetPageSize)(newPageSize)); };
    return [
        pageSize,
        onPageSizeChange,
    ];
};
exports.usePageSize = usePageSize;
