"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyTree = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var icons_material_1 = require("@mui/icons-material");
var material_1 = require("@mui/material");
var react_redux_1 = require("react-redux");
var strategy_tree_styles_1 = require("./strategy-tree.styles");
var tree_item_1 = require("./tree-item");
var section_title_1 = require("../../components/section-title");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../../localization");
var store_1 = require("../../../store");
var StrategyTree = function (_a) {
    var treeData = _a.treeData;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var selectedStrategyNodePath = (0, store_1.useSelectedTreeNodePath)();
    var checkIsNodeChanged = (0, hooks_1.useStrategyNodeChanges)().checkIsNodeChanged;
    var listExpanded = (0, store_1.useExpandedNodes)();
    var _b = (0, store_1.useProductTreeFilters)(), showEmptyNodes = _b.showEmptyNodes, showStrategyChanges = _b.showStrategyChanges;
    var handleToggle = function (_, nodeIds) { return dispatch((0, store_1.enrichAISetExpandedNodes)(nodeIds)); };
    var handleNodeSelect = function (_, nodePath) { return dispatch((0, store_1.enrichAISetSelectedTreeNodePath)(nodePath)); };
    var toggleNodesWithChanges = function (event) {
        dispatch((0, store_1.enrichAISetProductTreeFilters)({ showStrategyChanges: event.target.checked }));
    };
    var toggleFilterEmptyNodes = function (event) {
        dispatch((0, store_1.enrichAISetProductTreeFilters)({ showEmptyNodes: event.target.checked }));
    };
    var handleClearSelectedNode = selectedStrategyNodePath
        ? function () { return dispatch((0, store_1.enrichAISetSelectedTreeNodePath)(productTreeRootNodeId)); }
        : undefined;
    var renderedChildren = (0, react_1.useMemo)(function () {
        var _a;
        if (!((_a = treeData === null || treeData === void 0 ? void 0 : treeData.productTreeNode) === null || _a === void 0 ? void 0 : _a.children)) {
            return null;
        }
        return treeData.productTreeNode.children.reduce(function (acc, child) {
            var id = child.id, productPresentations = child.productPresentations;
            var childPath = "".concat(productTreeRootNodeId).concat(store_1.STRATEGY_NODE_PATH_SEPARATOR).concat(id);
            var isNodeChanged = checkIsNodeChanged(childPath);
            if (showStrategyChanges && !isNodeChanged) {
                return acc;
            }
            if (!showEmptyNodes && !(productPresentations === null || productPresentations === void 0 ? void 0 : productPresentations.totalCount)) {
                return acc;
            }
            var item = (react_1.default.createElement(tree_item_1.StrategyTreeItem, { key: "".concat(productTreeRootNodeId).concat(store_1.STRATEGY_NODE_PATH_SEPARATOR).concat(id), treeNode: child, parentPath: productTreeRootNodeId, isNodeChanged: checkIsNodeChanged(childPath) }));
            return __spreadArray(__spreadArray([], __read(acc), false), [item], false);
        }, []);
    }, [treeData, productTreeRootNodeId, checkIsNodeChanged, showStrategyChanges, showEmptyNodes]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(section_title_1.SectionTitle, { title: react_1.default.createElement(ui_components_1.Typography, { accent: true, variant: "button" }, t({ key: 'STRATEGY_PREVIEW_TITLE' })), onClear: handleClearSelectedNode }),
        react_1.default.createElement(strategy_tree_styles_1.StyledFormControlLabel, { control: react_1.default.createElement(ui_components_1.Switch, { size: "small", checked: showStrategyChanges, onChange: toggleNodesWithChanges }), label: react_1.default.createElement(ui_components_1.Typography, { variant: "body2", accent: true }, t({ key: 'CHANGES_ONLY' })) }),
        react_1.default.createElement(strategy_tree_styles_1.StyledFormControlLabel, { control: react_1.default.createElement(ui_components_1.Switch, { size: "small", checked: showEmptyNodes, onChange: toggleFilterEmptyNodes }), label: react_1.default.createElement(ui_components_1.Typography, { variant: "body2", accent: true }, t({ key: 'SHOW_EMPTY_CATEGORIES' })) }),
        react_1.default.createElement(material_1.Divider, null),
        react_1.default.createElement(strategy_tree_styles_1.StyledTree, { defaultCollapseIcon: react_1.default.createElement(icons_material_1.ExpandMore, { color: "primary", fontSize: "large" }), defaultExpandIcon: react_1.default.createElement(icons_material_1.ChevronRight, { color: "primary", fontSize: "large" }), expanded: listExpanded, selected: selectedStrategyNodePath, onNodeToggle: handleToggle, onNodeSelect: handleNodeSelect }, renderedChildren)));
};
exports.StrategyTree = StrategyTree;
exports.default = exports.StrategyTree;
