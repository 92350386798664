"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailsAttributes = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var product_details_attribute_1 = require("./product-details-attribute");
var product_details_attributes_constants_1 = require("./product-details-attributes.constants");
var product_details_attributes_styles_1 = require("./product-details-attributes.styles");
var attributes_1 = require("../../../client/attributes");
var use_special_attribute_value_hook_1 = require("../../../client/attributes/hooks/use-special-attribute-value.hook");
var store_1 = require("../../../../store");
var getMeasureString = function (value, unit) { return "".concat(value).concat(unit && unit !== product_details_attributes_constants_1.UNSPECIFIED_UNIT ? " ".concat(unit) : ''); };
var ProductDetailsAttributes = function (_a) {
    var productId = _a.productId, labels = _a.labels, productPresentationLabels = _a.productPresentationLabels, refetchProductPresentations = _a.refetchProductPresentations;
    var categoryLabel = (0, use_special_attribute_value_hook_1.useSpecialAttributeName)(use_special_attribute_value_hook_1.SPECIAL_ATTRIBUTES.CATEGORY);
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var notEditableLabels = (0, react_1.useMemo)(function () { return ([
        attributes_1.PRODUCT_DESCRIPTION_LABEL,
        categoryLabel,
    ]); }, [categoryLabel]);
    var formattedAttributes = (0, react_1.useMemo)(function () { return labels.reduce(function (acc, label) {
        var _a, _b;
        var _c, _d;
        var newAcc = __assign({}, acc);
        var labelTitle = label.label, origin = label.origin, unformattedValue = label.value, unit = label.unit;
        if (!labelTitle)
            return newAcc;
        var prevLabel = acc[labelTitle] || {};
        var value = getMeasureString(unformattedValue, unit);
        var isUnknown = value === attributes_1.SpecialAttributeValue.Unknown;
        var values = prevLabel.values || {};
        if (productPresentationLabels.length) {
            var productPresentationLabel = productPresentationLabels.find(function (productPresentationLabel) { return productPresentationLabel && productPresentationLabel.label === labelTitle; });
            if (!productPresentationLabel && !isUnknown)
                return newAcc;
            if (product_details_attributes_constants_1.notVisibleLabels.includes(labelTitle))
                return newAcc;
            if (!Object.keys(values).length) {
                newAcc[labelTitle] = {
                    label: labelTitle,
                    values: (_c = productPresentationLabel === null || productPresentationLabel === void 0 ? void 0 : productPresentationLabel.allowedValues.reduce(function (innerAcc, item) {
                        innerAcc[item] = {
                            unit: unit,
                            value: item,
                            valid: true,
                            selected: false,
                        };
                        return innerAcc;
                    }, {})) !== null && _c !== void 0 ? _c : {},
                };
            }
            var productPresentationLabelValues = !isUnknown ? productPresentationLabel.validValues
                .concat(productPresentationLabel.invalidValues) : [value];
            values = ((_d = newAcc[labelTitle]) === null || _d === void 0 ? void 0 : _d.values) || {};
            if (productPresentationLabelValues.includes(value)) {
                newAcc[labelTitle] = __assign(__assign({}, prevLabel), { label: labelTitle, disabled: notEditableLabels.includes(labelTitle) || !!unit, values: __assign(__assign({}, values), (_a = {}, _a[value] = {
                        unit: unit,
                        selected: true,
                        value: value,
                        origin: origin,
                        valid: productPresentationLabel === null || productPresentationLabel === void 0 ? void 0 : productPresentationLabel.validValues.includes(value),
                    }, _a)) });
            }
        }
        else {
            newAcc[labelTitle] = __assign(__assign({}, prevLabel), { label: labelTitle, disabled: true, values: __assign(__assign({}, values), (_b = {}, _b[value] = {
                    unit: unit,
                    selected: true,
                    value: value,
                    origin: origin,
                    valid: true,
                }, _b)) });
        }
        return newAcc;
    }, {}); }, [
        labels,
        productPresentationLabels,
    ]);
    var attributeList = (0, react_1.useMemo)(function () { return (0, ramda_1.sortWith)([
        (0, ramda_1.comparator)(function (label) { return (selectedAttributeNode && label === selectedAttributeNode.label); }),
        function (a, b) { return a.localeCompare(b); },
    ])(Object.keys(formattedAttributes)); }, [formattedAttributes, selectedAttributeNode]);
    var allValues = (0, react_1.useMemo)(function () { return attributeList.reduce(function (acc, attributeKey) {
        var _a;
        var labelValues = Object.values(((_a = formattedAttributes[attributeKey]) === null || _a === void 0 ? void 0 : _a.values) || {});
        var notApplicableValue = labelValues.find(function (_a) {
            var value = _a.value;
            return value === attributes_1.SpecialAttributeValue.NotApplicable;
        });
        if (!notApplicableValue) {
            labelValues = __spreadArray(__spreadArray([], __read(labelValues), false), [
                {
                    value: attributes_1.SpecialAttributeValue.NotApplicable,
                    selected: false,
                    valid: false,
                },
            ], false);
        }
        acc[attributeKey] = labelValues;
        return acc;
    }, {}); }, [attributeList, formattedAttributes]);
    return (react_1.default.createElement(product_details_attributes_styles_1.StyledWrapper, null, attributeList.map(function (attributeKey) {
        var _a, _b;
        return (react_1.default.createElement(product_details_attribute_1.ProductDetailsAttribute, { key: attributeKey, productId: productId, label: (_a = formattedAttributes[attributeKey]) === null || _a === void 0 ? void 0 : _a.label, disabled: (_b = formattedAttributes[attributeKey]) === null || _b === void 0 ? void 0 : _b.disabled, values: allValues[attributeKey], refetchProductPresentations: refetchProductPresentations }));
    })));
};
exports.ProductDetailsAttributes = ProductDetailsAttributes;
