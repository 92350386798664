"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENRICH_AI_SET_SEARCH = exports.ENRICH_AI_SET_PAGE_SIZE = exports.ENRICH_AI_SET_IS_LIST_VIEW_MODE = exports.ENRICH_AI_SET_IS_AGGREGATED_ATTRIBUTE_VIEW_HIDDEN = exports.ENRICH_AI_SET_PRODUCT_TREE_FILTERS = exports.ENRICH_AI_SET_ATTRIBUTES_SORT = exports.ENRICH_AI_SET_ATTRIBUTES_COLLAPSE_STATE = exports.ENRICH_AI_SET_ATTRIBUTES_EXCLUDED_ITEMS = exports.ENRICH_AI_SET_SELECTED_ATTRIBUTE_NODE = exports.ENRICH_AI_SET_EXPANDED_NODES = exports.ENRICH_AI_REMOVE_CHANGES = exports.ENRICH_AI_ADD_CHANGES = exports.ENRICH_AI_SET_PRODUCT_TREE = exports.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH = exports.ENRICH_AI_SET_SELECTED_PROJECT = exports.ENRICH_AI_SET_SELECTED_CATALOG = void 0;
var const_1 = require("../../const");
exports.ENRICH_AI_SET_SELECTED_CATALOG = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_CATALOG");
exports.ENRICH_AI_SET_SELECTED_PROJECT = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_PROJECT");
exports.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_TREE_NODE_PATH");
exports.ENRICH_AI_SET_PRODUCT_TREE = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_PRODUCT_TREE");
exports.ENRICH_AI_ADD_CHANGES = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_ADD_CHANGES");
exports.ENRICH_AI_REMOVE_CHANGES = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_REMOVE_CHANGES");
exports.ENRICH_AI_SET_EXPANDED_NODES = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_EXPANDED_NODES");
exports.ENRICH_AI_SET_SELECTED_ATTRIBUTE_NODE = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_ATTRIBUTE_NODE");
exports.ENRICH_AI_SET_ATTRIBUTES_EXCLUDED_ITEMS = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_ATTRIBUTES_EXCLUDED_ITEMS");
exports.ENRICH_AI_SET_ATTRIBUTES_COLLAPSE_STATE = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_ATTRIBUTES_COLLAPSE_STATE");
exports.ENRICH_AI_SET_ATTRIBUTES_SORT = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_ATTRIBUTES_SORT");
exports.ENRICH_AI_SET_PRODUCT_TREE_FILTERS = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_PRODUCT_TREE_FILTERS");
exports.ENRICH_AI_SET_IS_AGGREGATED_ATTRIBUTE_VIEW_HIDDEN = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_IS_AGGREGATED_ATTRIBUTE_VIEW_HIDDEN");
exports.ENRICH_AI_SET_IS_LIST_VIEW_MODE = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_IS_LIST_VIEW_MODE");
exports.ENRICH_AI_SET_PAGE_SIZE = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_PAGE_SIZE");
exports.ENRICH_AI_SET_SEARCH = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SEARCH");
