"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewProductsWidgetContent = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var preview_products_widget_content_styles_1 = require("./preview-products-widget-content.styles");
var products_preview_1 = require("./products-preview");
var strategy_attributes_1 = require("../strategy-attributes");
var store_1 = require("../../../store");
var PreviewProductsWidgetContent = function (_a) {
    var onReEnrich = _a.onReEnrich;
    var dispatch = (0, react_redux_1.useDispatch)();
    var isAggregatedAttributeViewHidden = (0, store_1.useIsAggregatedAttributeViewHidden)();
    var isListViewMode = (0, store_1.useIsListViewMode)();
    var toggleAttributeSectionVisibility = (0, react_1.useCallback)(function () { return dispatch((0, store_1.enrichAISetIsAggregatedAttributeViewHidden)(!isAggregatedAttributeViewHidden)); }, [dispatch, isAggregatedAttributeViewHidden]);
    var toggleViewMode = (0, react_1.useCallback)(function () { return dispatch((0, store_1.enrichAISetIsListViewMode)(!isListViewMode)); }, [dispatch, isListViewMode]);
    return (react_1.default.createElement(preview_products_widget_content_styles_1.StyledProductsContainer, null,
        react_1.default.createElement(preview_products_widget_content_styles_1.StyledTabsContainer, null,
            !isAggregatedAttributeViewHidden && (react_1.default.createElement(strategy_attributes_1.StrategyAttributesContainer, { onReEnrich: onReEnrich })),
            react_1.default.createElement(products_preview_1.ProductsPreviewContainer, { isAttributesSectionVisible: !isAggregatedAttributeViewHidden, isListViewMode: isListViewMode, toggleAttributeSectionVisibility: toggleAttributeSectionVisibility, toggleViewMode: toggleViewMode }))));
};
exports.PreviewProductsWidgetContent = PreviewProductsWidgetContent;
