"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT = void 0;
var types_1 = require("../actions/types");
var client_constants_1 = require("../../enrich-ai/client/client.constants");
var models_1 = require("../../enrich-ai/models");
exports.DEFAULT = {
    selectedCatalog: undefined,
    selectedProject: undefined,
    selectedTreeNodePath: undefined,
    productTree: undefined,
    changes: undefined,
    expandedNodes: undefined,
    selectedAttributeNode: null,
    search: null,
    pageSize: client_constants_1.PAGE_SIZE_DEFAULT,
    isAggregatedAttributeViewHidden: false,
    isListViewMode: false,
    aggregatedAttributes: {
        excludedItems: [],
        collapseState: models_1.CollapseState.Collapse,
        sort: models_1.SortingItem.SortByTextAsc,
    },
    productTreeFilters: {
        showEmptyNodes: false,
        showStrategyChanges: false,
    },
};
// Complexity will be fixed in https://groupby.atlassian.net/browse/EN-3905
// eslint-disable-next-line complexity
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_SELECTED_CATALOG:
            return enrichAISetSelectedCatalogReducer(state, action.payload.selectedCatalog);
        case types_1.ENRICH_AI_SET_SELECTED_PROJECT:
            return enrichAISetSelectedProjectReducer(state, action.payload.selectedProject);
        case types_1.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH:
            return enrichAISetSelectedTreeNodePathReducer(state, action.payload.selectedTreeNodePath);
        case types_1.ENRICH_AI_SET_PRODUCT_TREE:
            return enrichAISetProductTreeIdReducer(state, action.payload.productTree);
        case types_1.ENRICH_AI_ADD_CHANGES:
            return enrichAIAddChangesReducer(state, action.payload.changes);
        case types_1.ENRICH_AI_REMOVE_CHANGES:
            return enrichAIRemoveChangesReducer(state, action.payload.strategyNodePath);
        case types_1.ENRICH_AI_SET_EXPANDED_NODES:
            return enrichAISetExpandedNodesReducer(state, action.payload.expandedNodes);
        case types_1.ENRICH_AI_SET_SELECTED_ATTRIBUTE_NODE:
            return enrichAISetSelectedAttributeNodeReducer(state, action.payload.selectedAttributeNode);
        case types_1.ENRICH_AI_SET_ATTRIBUTES_EXCLUDED_ITEMS:
            return enrichAISetAttributesExcludedItemsReducer(state, action.payload.excludedItems);
        case types_1.ENRICH_AI_SET_ATTRIBUTES_COLLAPSE_STATE:
            return enrichAISetAttributesCollapseStateReducer(state, action.payload.collapseState);
        case types_1.ENRICH_AI_SET_ATTRIBUTES_SORT:
            return enrichAISetAttributesSortReducer(state, action.payload.sort);
        case types_1.ENRICH_AI_SET_PRODUCT_TREE_FILTERS:
            return enrichAISetTreeFiltersReducer(state, action.payload.productTreeFilters);
        case types_1.ENRICH_AI_SET_IS_LIST_VIEW_MODE:
            return enrichAISetIsListViewModeReducer(state, action.payload.isListViewMode);
        case types_1.ENRICH_AI_SET_IS_AGGREGATED_ATTRIBUTE_VIEW_HIDDEN:
            return enrichAISetIsAggregatedAttributeViewHiddenReducer(state, action.payload.isAggregatedAttributeViewHidden);
        case types_1.ENRICH_AI_SET_PAGE_SIZE:
            return enrichAISetPageSizeReducer(state, action.payload.pageSize);
        case types_1.ENRICH_AI_SET_SEARCH:
            return enrichAISetSearchReducer(state, action.payload.search);
        default:
            return state;
    }
});
var enrichAISetSelectedCatalogReducer = function (state, selectedCatalog) {
    var currentCatalog = state.selectedCatalog;
    var currentCatalogId = currentCatalog === null || currentCatalog === void 0 ? void 0 : currentCatalog.id;
    var relatedClearedState = {};
    if (currentCatalogId !== selectedCatalog.id) {
        relatedClearedState = {
            productTree: exports.DEFAULT.productTree,
            selectedTreeNodePath: exports.DEFAULT.selectedTreeNodePath,
            expandedNodes: exports.DEFAULT.expandedNodes,
            search: exports.DEFAULT.search,
            aggregatedAttributes: exports.DEFAULT.aggregatedAttributes,
        };
    }
    return __assign(__assign(__assign({}, state), { selectedCatalog: selectedCatalog }), relatedClearedState);
};
var enrichAISetSelectedProjectReducer = function (state, selectedProject) {
    var currentProject = state.selectedProject;
    var currentProjectId = currentProject === null || currentProject === void 0 ? void 0 : currentProject.id;
    var relatedClearedState = {};
    if (currentProjectId !== selectedProject.id) {
        relatedClearedState = {
            selectedCatalog: exports.DEFAULT.selectedCatalog,
            productTree: exports.DEFAULT.productTree,
            selectedTreeNodePath: exports.DEFAULT.selectedTreeNodePath,
            expandedNodes: exports.DEFAULT.expandedNodes,
            search: exports.DEFAULT.search,
            aggregatedAttributes: exports.DEFAULT.aggregatedAttributes,
        };
    }
    return __assign(__assign(__assign({}, state), { selectedProject: selectedProject }), relatedClearedState);
};
var enrichAISetSelectedTreeNodePathReducer = function (state, selectedTreeNodePath) { return (__assign(__assign({}, state), { selectedTreeNodePath: selectedTreeNodePath, selectedAttributeNode: null })); };
var enrichAISetProductTreeIdReducer = function (state, productTree) { return (__assign(__assign({}, state), { productTree: productTree })); };
var enrichAIAddChangesReducer = function (state, newChanges) {
    var _a;
    var _b;
    var changes = state.changes, selectedCatalog = state.selectedCatalog;
    if (!selectedCatalog) {
        return state;
    }
    var catalogId = selectedCatalog.id;
    var catalogChanges = __spreadArray(__spreadArray([], __read(((_b = changes === null || changes === void 0 ? void 0 : changes[catalogId]) !== null && _b !== void 0 ? _b : [])), false), __read(newChanges), false);
    return __assign(__assign({}, state), { changes: __assign(__assign({}, changes), (_a = {}, _a[catalogId] = catalogChanges, _a)) });
};
var enrichAIRemoveChangesReducer = function (state, strategyNodePath) {
    var _a, _b;
    var _c;
    var changes = state.changes, selectedCatalog = state.selectedCatalog;
    if (!selectedCatalog) {
        return state;
    }
    var catalogId = selectedCatalog.id;
    if (strategyNodePath) {
        var catalogChanges = (_c = changes === null || changes === void 0 ? void 0 : changes[catalogId]) !== null && _c !== void 0 ? _c : [];
        return __assign(__assign({}, state), { changes: __assign(__assign({}, changes), (_a = {}, _a[catalogId] = catalogChanges.filter(function (_a) {
                var path = _a.path;
                return !path.startsWith(strategyNodePath);
            }), _a)) });
    }
    return __assign(__assign({}, state), { changes: __assign(__assign({}, changes), (_b = {}, _b[catalogId] = undefined, _b)) });
};
var enrichAISetExpandedNodesReducer = function (state, expandedNodes) { return (__assign(__assign({}, state), { expandedNodes: expandedNodes })); };
var enrichAISetSelectedAttributeNodeReducer = function (state, selectedAttributeNode) { return (__assign(__assign({}, state), { selectedAttributeNode: selectedAttributeNode })); };
var enrichAISetAttributesExcludedItemsReducer = function (state, excludedItems) { return (__assign(__assign({}, state), { aggregatedAttributes: __assign(__assign({}, state.aggregatedAttributes), { excludedItems: excludedItems }) })); };
var enrichAISetAttributesCollapseStateReducer = function (state, collapseState) { return (__assign(__assign({}, state), { aggregatedAttributes: __assign(__assign({}, state.aggregatedAttributes), { collapseState: collapseState }) })); };
var enrichAISetAttributesSortReducer = function (state, sort) { return (__assign(__assign({}, state), { aggregatedAttributes: __assign(__assign({}, state.aggregatedAttributes), { sort: sort }) })); };
var enrichAISetTreeFiltersReducer = function (state, productTreeFilters) { return (__assign(__assign({}, state), { productTreeFilters: __assign(__assign({}, state.productTreeFilters), productTreeFilters) })); };
var enrichAISetIsListViewModeReducer = function (state, isListViewMode) { return (__assign(__assign({}, state), { isListViewMode: isListViewMode })); };
var enrichAISetIsAggregatedAttributeViewHiddenReducer = function (state, isAggregatedAttributeViewHidden) { return (__assign(__assign({}, state), { isAggregatedAttributeViewHidden: isAggregatedAttributeViewHidden })); };
var enrichAISetPageSizeReducer = function (state, pageSize) { return (__assign(__assign({}, state), { pageSize: pageSize })); };
var enrichAISetSearchReducer = function (state, search) { return (__assign(__assign({}, state), { search: search })); };
