"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsPreviewContainer = void 0;
var react_1 = __importStar(require("react"));
var client_1 = require("@apollo/client");
var react_redux_1 = require("react-redux");
var products_action_panel_1 = require("./products-action-panel");
var products_list_view_1 = require("./products-list-view");
var products_preview_component_1 = require("./products-preview.component");
var products_preview_styles_1 = require("./products-preview.styles");
var use_entity_select_hook_1 = require("./use-entity-select.hook");
var product_details_dialog_component_1 = require("../../product-details-dialog/product-details-dialog.component");
var bulk_edit_product_attributes_dialog_1 = require("../bulk-edit-product-attributes-dialog");
var reclassify_products_dialog_component_1 = require("../reclassify-products-dialog/reclassify-products-dialog.component");
var product_1 = require("../../../client/product");
var get_product_tree_query_1 = require("../../../client/product-tree/get-product-tree.query");
var hooks_1 = require("../../../hooks");
var hooks_2 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var store_1 = require("../../../../store");
var ProductsPreviewContainer = function (_a) {
    var isListViewMode = _a.isListViewMode, productsActionPanelProps = __rest(_a, ["isListViewMode"]);
    var client = (0, client_1.useApolloClient)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var addSuccess = (0, hooks_2.useNotification)().addSuccess;
    var _b = __read((0, react_1.useState)([]), 2), products = _b[0], setProducts = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), reclassifyDialogOpened = _c[0], setReclassifyDialogOpened = _c[1];
    var productTreeId = (0, store_1.useProductTreeId)();
    var _d = __read((0, react_1.useState)(null), 2), reclassifyFilter = _d[0], setReclassifyFilter = _d[1];
    var _e = __read((0, react_1.useState)(false), 2), isBulkSelectionModeEnabled = _e[0], setIsBulkSelectionModeEnabled = _e[1];
    var _f = __read((0, react_1.useState)(), 2), activeProductId = _f[0], setActiveProductId = _f[1];
    var _g = __read((0, react_1.useState)(false), 2), isCustomizeViewOpen = _g[0], setIsCustomizeViewOpen = _g[1];
    var _h = __read((0, react_1.useState)(false), 2), isBulkEditDialogOpen = _h[0], setIsBulkEditDialogOpen = _h[1];
    var searchTerm = (0, store_1.useSearch)();
    var _j = __read((0, react_1.useState)(0), 2), totalCount = _j[0], setTotalCount = _j[1];
    var _k = __read((0, react_1.useState)(false), 2), singleReclassify = _k[0], setSingleReclassify = _k[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var setSearchTerm = (0, react_1.useCallback)(function (search) { return dispatch((0, store_1.enrichAISetSearch)(search)); }, [dispatch]);
    var t = (0, localization_1.useLocalization)().formatMessage;
    var expandedNodes = (0, store_1.useExpandedNodes)();
    var _l = (0, use_entity_select_hook_1.useEntitySelectHook)(), ids = _l.ids, selectAll = _l.selectAll, setIds = _l.setIds, handleToggleSelection = _l.handleToggleSelection, handleResetSelection = _l.handleResetSelection, checkItemSelected = _l.checkItemSelected;
    var handleBulkReclassify = function () {
        var attributeNodeFilter;
        if (selectedAttributeNode) {
            attributeNodeFilter = {
                product: {
                    attributes: (0, product_1.createFilterFromAttributeNode)(selectedAttributeNode),
                },
            };
        }
        setReclassifyFilter(__assign(__assign({}, attributeNodeFilter ? attributeNodeFilter.product : {}), { id: __assign({}, selectAll ? { notIn: ids } : { in: ids }) }));
        setReclassifyDialogOpened(true);
    };
    var handleSingleReclassify = function (id) {
        setSingleReclassify(true);
        setReclassifyFilter({
            id: { in: [id] },
        });
        setReclassifyDialogOpened(true);
    };
    var handleReclassifySuccess = function (params) {
        setReclassifyDialogOpened(false);
        setSingleReclassify(false);
        var newExpandedNodes = new Set(expandedNodes);
        (0, hooks_1.addPathRecursive)(params.targetNodePath, newExpandedNodes);
        dispatch((0, store_1.enrichAISetExpandedNodes)(Array.from(newExpandedNodes)));
        dispatch((0, store_1.enrichAISetSelectedTreeNodePath)(params.targetNodePath));
        handleResetSelection();
        void client.refetchQueries({
            include: [get_product_tree_query_1.GET_PRODUCT_TREE],
        });
        addSuccess(t({
            key: 'PRODUCT_RECLASSIFY_SUCCESS_MESSAGE',
            values: {
                totalCount: params.totalCount,
                title: params.targetNodeTitle,
            },
        }));
    };
    var handleToggleBulkSelectionMode = function () { return setIsBulkSelectionModeEnabled(function (oldValue) { return !oldValue; }); };
    var handleCompleted = (0, react_1.useCallback)(function (data) {
        setTotalCount(data.productTreeNode.productPresentations.totalCount);
    }, []);
    var commonProps = {
        setProducts: setProducts,
        selected: ids,
        totalCount: totalCount,
        activeProductId: activeProductId,
        isSelectable: isBulkSelectionModeEnabled,
        selectAll: selectAll,
        onToggleSelection: handleToggleSelection,
        checkItemSelected: checkItemSelected,
        onSelect: setIds,
        search: searchTerm,
        isCustomizeViewOpen: isCustomizeViewOpen,
        selectedAttributeNode: selectedAttributeNode,
        onActiveProductIdChange: setActiveProductId,
        onCompleted: handleCompleted,
        onCustomizeViewClose: function () { return setIsCustomizeViewOpen(false); },
    };
    var selectedItemsCount = selectAll ? totalCount - ids.length : ids.length;
    var activeProductIdx = products.findIndex(function (product) { return product.id === activeProductId; });
    var handleNextClick = function () {
        if (activeProductIdx < products.length - 1) {
            setActiveProductId(products[activeProductIdx + 1].id);
        }
        else {
            setActiveProductId(products[0].id);
        }
    };
    var handlePreviousClick = function () {
        if (activeProductIdx > 0) {
            setActiveProductId(products[activeProductIdx - 1].id);
        }
        else {
            setActiveProductId(products[products.length - 1].id);
        }
    };
    return (react_1.default.createElement(products_preview_styles_1.StyledProductPreviewSectionContainer, null,
        react_1.default.createElement(products_action_panel_1.ProductsActionPanel, __assign({}, productsActionPanelProps, { isListViewMode: isListViewMode, totalCount: totalCount, searchTerm: searchTerm !== null && searchTerm !== void 0 ? searchTerm : '', onSearch: setSearchTerm, isBulkSelectionModeEnabled: isBulkSelectionModeEnabled, toggleBulkSelectionMode: handleToggleBulkSelectionMode, selectedItemsCount: selectedItemsCount, onReclassify: handleBulkReclassify, onEdit: function () { return setIsBulkEditDialogOpen(true); }, onSettingsClick: function () { return setIsCustomizeViewOpen(true); } })),
        reclassifyDialogOpened && (react_1.default.createElement(reclassify_products_dialog_component_1.ReclassifyProductsDialog, { productTreeId: productTreeId, onClose: function () {
                setReclassifyDialogOpened(false);
                setSingleReclassify(false);
            }, onSuccess: handleReclassifySuccess, productFilter: reclassifyFilter, selectedStrategyNodeId: selectedStrategyNodeId, selectedItemsCount: singleReclassify ? 1 : selectedItemsCount })),
        isBulkEditDialogOpen && (react_1.default.createElement(bulk_edit_product_attributes_dialog_1.BulkEditProductAttributesDialog, { selectAll: selectAll, excludedIds: ids, onSubmit: function () {
                handleResetSelection();
                setIsBulkEditDialogOpen(false);
            }, onClose: function () { return setIsBulkEditDialogOpen(false); } })),
        isListViewMode
            ? react_1.default.createElement(products_list_view_1.ProductsListView, __assign({}, commonProps))
            : react_1.default.createElement(products_preview_component_1.ProductsPreview, __assign({}, commonProps)),
        !!activeProductId && (react_1.default.createElement(product_details_dialog_component_1.ProductDetailsDialog, { selectedProductId: activeProductId, onClose: function () { return setActiveProductId(undefined); }, onNext: handleNextClick, onPrevious: handlePreviousClick, onReclassify: function () {
                handleSingleReclassify(activeProductId);
                setActiveProductId(undefined);
            } }))));
};
exports.ProductsPreviewContainer = ProductsPreviewContainer;
exports.default = exports.ProductsPreviewContainer;
